body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, html{
  padding:0px;
  margin:0px;
  color: #333333;
}
ul{
  margin: 0px;
}
#root{
  background-image: url('https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fbackgroundmountain.jpg?alt=media&token=149c8325-eb00-42af-b31d-55288e15873b');
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;
  height: 100%;
  margin: 0px;
  padding: 0px;
  min-height:100vh;
}
.leftsite,
.rightside{
  width: 46%;
  padding: 0px 2%;
  display:inline-block;
  vertical-align: top;
}
.rightside{
  text-align:center;
}
.circlelogo{
  width:36px;
  margin: 0px 0px 8px -3px;
}
.muzelogo{
  width: 131px;
}
.maindiv{
  padding:20px;
}
h1{
  font-size: 28px;
    width: 400px;
    max-width: 100%;
    margin-top: 40px;
}
h2{
  font-size: 16px;
    font-weight: 500;
    width: 390px;
    max-width: 100%;
    padding-bottom:20px;
}
h3{
      text-align: center;
}
h4{
  display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    font-weight: 400;
    margin: 0px;
}
h5{
  font-size: 13px;
  color: #59356C;
  margin: 5px 0px;
}
a{
  color: #333;
  font-size: 13px;
}
button{
  cursor: pointer;
}
@media only screen and (min-width: 1100px){
  .leftsite{
    width: 38%;
    padding-left: 8%;
  }
}
