.halffield{
  width: 50%;
  display: inline-block;
  padding-bottom: 20px;
}
label{
  display: block;
  font-size: 13px;
  padding-bottom: 10px;
}
input[type='password'],
input[type='text']{
    display: block;
    border: 0px !important;
    height: 30px;
    box-shadow: 0px 1px 3px #949494;
    border-radius: 5px;
    width: 85%;
    padding-left: 12px;
}
.fullfield label{
  display: inline-block;
}
.fullfield{
  padding-bottom: 20px;
}
.fullfield a,
.fullfield a:hover,
.fullfield a:focus,
.fullfield a:visited{
  color:#333333;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
    width: 19px;
    height: 19px;
    background-color: #fff;
    border-radius: 6px!important;
    border: 1px solid #969696;
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 1px;
    display: inline-block;
    vertical-align: middle;
}
input[type="checkbox"]:checked {
  background-color: #2779D9;
}
.formbuttons button{
  background-color: #2779D9;
  border: 1px solid #085ABA;
  padding: 10px 20px;
    border-radius: 3px;
    color: #ffffff;
    font-weight: 600;
    margin-right: 30px;
    font-size:14px;
    margin-top: 0px !important;
    display: inline-block;
    vertical-align: top;
}
.formbuttons button:disabled{
  background-color: #ebebed;
border: 1px solid #bdbfc7;
color: #ccc;
}
.formbuttons a{
  background-color: #fff;
    border: 1px solid #a0a0a0;
    padding: 10px 20px;
    border-radius: 3px;
    color: #333;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
    font-size:14px;
}
form{
  width: 400px;
    max-width: 100%;
}
.error{
  margin-top: -20px;
    background-color: red;
    padding: 8px 12px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}
.success{
  background-color: green;
    margin-top: -20px;
    padding: 8px 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}
