.maincomp{
  width: 100%;
}
.starss{
  text-align: center;
  padding-top: 20px;
}
.starss img.star{
  width: 20px;
  padding: 2px;
}
.maincomp{
  margin-top: 120px;
  width:90%;
}
.reviewauthor{
  text-align: center;
}
.review{
  display: inline-block;
    vertical-align: middle;
    width: 65px;
    padding-left: 8px;
}
