.messages{
  display: none;
}
.thirdside{
  width: 27%;
padding: 20px 3%;
display: inline-block;
vertical-align: top;
}
.maindashboard{
  clear:both;
}
